.background {
    background-color: #0e0e0e;
    /* height: 480px; */
    height: auto;
    color: #fff;
    width: 100%;
    padding-bottom: 75px;
    /* display: block; */
}

.sorted {
    padding: 20px;
    margin-left: 20px;
}

.image_and_text {
    margin-top: 50px;
    margin-right: 200px;
    margin-left: 200px;
    display: flex;
    justify-content: space-around;
}

@media (max-width: 768px) {
    .image_and_text {
        margin-top: 0px;
        margin-right: 20px;
        margin-left: 20px;
        display: block;
    }
}

.text-section {
    display: block;
}