.landing {
  position: absolute;
  width: 99%;
  height: 870px;
  margin: 0px;
}

.iframe {
  width: 95%;
  height: 100%;
}
