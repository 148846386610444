@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;900&display=swap');

body {
  font-family: 'Raleway', sans-serif;
  letter-spacing: 1px;
}

.color {
  background: rgba(34, 33, 33, 0.473);
}

.App {
  font-family: sans-serif;
  text-align: center;
}

::-webkit-scrollbar {
  display: none;
}

.progress-bar {
  position: fixed;
  top: 200px;
  left: 130px;
  background: #8F1111;
  width: 3px;
  height: 650px;
  border-radius: 0px 0px 10px 10px;
  transform-origin: 0 0;
  z-index: 10;
}

.button {
  padding: 10px;
  border-radius: 25px;
  border: none;
  margin-top: 20px;
  margin-left: 20px;
  font-size: 14px;
  padding-left: 25px;
  padding-right: 25px;
  cursor: pointer;
  font-weight: 600;
}

.button:hover {
  color: white;
  background:#8F1111;
  font-weight: 400;
}

.button2 {
  padding: 10px;
  color: #161616;
  border-radius: 10px;
  border: none;
  margin-top: 20px;
  margin-left: 20px;
  font-size: 14px;
  padding-left: 25px;
  padding-right: 25px;
  cursor: pointer;
  filter: drop-shadow(1px 0 2px rgba(207, 207, 207, 0.555));
  font-weight: 600;
}

.button2:hover {
  color: white;
  transform: scale(1.1, 1.1);
  background:#8F1111;
  font-weight: 400;
}

/* .hide_scroll{

} */

@media (max-width: 768px) {
    .hide_scroll{
      display: none;
  }
}