.cta_text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center_cta {
    margin-top: 15%;
    display: block;
    justify-content: center;
    align-items: center;   
}

.video_size {
    width: 99%;
}

@media (max-width: 768px) {
    .video_size {
        width: 95%;
    }
}

.around_video_size {
    height: 771px;
}

.area_size {
    height: 771px;
}

@media (max-width: 1280px) {
    .area_size {
        height: 482px;
        /* margin-bottom: 20px */
    }
}

@media (max-width: 768px) {
    .area_size {
        height: 220px;
        /* margin-bottom: 20px */
    }
}

@media (max-width: 400px) {
    .area_size {
        height: 65px;
        /* margin-bottom: 20px */
    }
}