@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;900&display=swap);
body {
  font-family: 'Raleway', sans-serif;
  letter-spacing: 1px;
}

.color {
  background: rgba(34, 33, 33, 0.473);
}

.App {
  font-family: sans-serif;
  text-align: center;
}

::-webkit-scrollbar {
  display: none;
}

.progress-bar {
  position: fixed;
  top: 200px;
  left: 130px;
  background: #8F1111;
  width: 3px;
  height: 650px;
  border-radius: 0px 0px 10px 10px;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  z-index: 10;
}

.button {
  padding: 10px;
  border-radius: 25px;
  border: none;
  margin-top: 20px;
  margin-left: 20px;
  font-size: 14px;
  padding-left: 25px;
  padding-right: 25px;
  cursor: pointer;
  font-weight: 600;
}

.button:hover {
  color: white;
  background:#8F1111;
  font-weight: 400;
}

.button2 {
  padding: 10px;
  color: #161616;
  border-radius: 10px;
  border: none;
  margin-top: 20px;
  margin-left: 20px;
  font-size: 14px;
  padding-left: 25px;
  padding-right: 25px;
  cursor: pointer;
  -webkit-filter: drop-shadow(1px 0 2px rgba(207, 207, 207, 0.555));
          filter: drop-shadow(1px 0 2px rgba(207, 207, 207, 0.555));
  font-weight: 600;
}

.button2:hover {
  color: white;
  -webkit-transform: scale(1.1, 1.1);
          transform: scale(1.1, 1.1);
  background:#8F1111;
  font-weight: 400;
}

/* .hide_scroll{

} */

@media (max-width: 768px) {
    .hide_scroll{
      display: none;
  }
}
.brand-login {
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    border: solid 1px #fff;
    background-color: transparent;
    color: #fff;
    font-weight: 700;
}

.brand-login:hover {
    -webkit-transform: scale(1.01);
            transform: scale(1.01); 
    background-color: #8F1111;
  }
.background {
    background-color: #0e0e0e;
    /* height: 480px; */
    height: auto;
    color: #fff;
    width: 100%;
    padding-bottom: 75px;
    /* display: block; */
}

.sorted {
    padding: 20px;
    margin-left: 20px;
}

.image_and_text {
    margin-top: 50px;
    margin-right: 200px;
    margin-left: 200px;
    display: flex;
    justify-content: space-around;
}

@media (max-width: 768px) {
    .image_and_text {
        margin-top: 0px;
        margin-right: 20px;
        margin-left: 20px;
        display: block;
    }
}

.text-section {
    display: block;
}
.why {
    display: flex;
}

@media (max-width: 768px) {
    .why {
        display: block;
    }
}

.body2 {
    display: flex;
    /* width: 30px; */
    margin-left: 20%
}

@media (max-width: 768px) {
    .body2 {
        width: 300px;
        margin-left: 10%
    }
}
.brands {
    background: #0e0e0e;
    margin-top: 930px;
    max-width: 100%;
    padding: 20px;
}


.headline {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    font-size: 24px;
    font-weight: 800;
    color: #fff;
    margin-bottom: 20px;
}
.cta_text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center_cta {
    margin-top: 15%;
    display: block;
    justify-content: center;
    align-items: center;   
}

.video_size {
    width: 99%;
}

@media (max-width: 768px) {
    .video_size {
        width: 95%;
    }
}

.around_video_size {
    height: 771px;
}

.area_size {
    height: 771px;
}

@media (max-width: 1280px) {
    .area_size {
        height: 482px;
        /* margin-bottom: 20px */
    }
}

@media (max-width: 768px) {
    .area_size {
        height: 220px;
        /* margin-bottom: 20px */
    }
}

@media (max-width: 400px) {
    .area_size {
        height: 65px;
        /* margin-bottom: 20px */
    }
}
.test {
  padding: 20;
  margin-left: 57%;
}

@media (max-width: 768px) {
  .test {
    padding: 20;
    margin-left: 5%;
  }
}

.spline_size {
  width: 99%;
}

.move_text_left {
  margin-left: 37%;
  padding: 20px;
}

@media (max-width: 768px) {
  .flex-block {
    width: 95%;
  }
}
@media (max-width: 768px) {
  .spline_size {
    width: 95%;
  }
}

.text_width {
  max-width: 235px;
}

@media (max-width: 768px) {
  .text_width {
    width: 100%;
  }
}

.image_and_text2 {
  /* margin-top: 50px; */
  margin-right: 200px;
  margin-left: 200px;
  display: flex;
  justify-content: space-around;
}

@media (max-width: 768px) {
  .image_and_text2 {
    margin-top: 0px;
    margin-right: 20px;
    margin-left: 20px;
    display: block;
  }
}

.phone_margin {
    margin-left: 225px;
}


@media (max-width: 768px) {
    .phone_margin {
        margin-left: 25px;
    }
}
.landing {
  position: absolute;
  width: 99%;
  height: 870px;
  margin: 0px;
}

.iframe {
  width: 95%;
  height: 100%;
}

