.brands {
    background: #0e0e0e;
    margin-top: 930px;
    max-width: 100%;
    padding: 20px;
}


.headline {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    font-size: 24px;
    font-weight: 800;
    color: #fff;
    margin-bottom: 20px;
}