.why {
    display: flex;
}

@media (max-width: 768px) {
    .why {
        display: block;
    }
}

.body2 {
    display: flex;
    /* width: 30px; */
    margin-left: 20%
}

@media (max-width: 768px) {
    .body2 {
        width: 300px;
        margin-left: 10%
    }
}