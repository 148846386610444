.test {
  padding: 20;
  margin-left: 57%;
}

@media (max-width: 768px) {
  .test {
    padding: 20;
    margin-left: 5%;
  }
}

.spline_size {
  width: 99%;
}

.move_text_left {
  margin-left: 37%;
  padding: 20px;
}

@media (max-width: 768px) {
  .flex-block {
    width: 95%;
  }
}
@media (max-width: 768px) {
  .spline_size {
    width: 95%;
  }
}

.text_width {
  max-width: 235px;
}

@media (max-width: 768px) {
  .text_width {
    width: 100%;
  }
}

.image_and_text2 {
  /* margin-top: 50px; */
  margin-right: 200px;
  margin-left: 200px;
  display: flex;
  justify-content: space-around;
}

@media (max-width: 768px) {
  .image_and_text2 {
    margin-top: 0px;
    margin-right: 20px;
    margin-left: 20px;
    display: block;
  }
}
